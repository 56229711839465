// MessagePackModals.jsx
import React from 'react';
import PropTypes from 'prop-types';

import ModalView from '../container/ModalView.js';

import UniversalContainer from '../component/UniversalContainer.jsx';

import { SHOP_ID } from '../resource/shopConstants.js';

export const MessagePackModals = ({ shopId = SHOP_ID.messagePack }) => {
  return (
    <>
      <ModalView id={`${shopId}.ConfirmOrderEmailInput`}>
        <UniversalContainer filename="ConfirmOrderEmailInput" shopId={shopId} />
      </ModalView>
    </>
  );
};

MessagePackModals.propTypes = {
  shopId: PropTypes.string,
};

export default MessagePackModals;
